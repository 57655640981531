/*var db = firebase.firestore();

//Init Data


const logoutButton = document.querySelector('#userLogout');
logoutButton.addEventListener('onlol', (e) => {
  //e.preventDefault();
  
 
  firebase.auth().signOut().then(function() {
    console.log('Signed Out');
  }, function(error) {
    console.error('Sign Out Error', error);
  });


});*/

document.getElementById("userLogout").onclick = function(event) {
    firebase.auth().signOut().then(function() {
        console.log('Signed Out');
        window.location = 'index.html';
      }, function(error) {
        console.error('Sign Out Error', error);
      });
}
